import React, { useState, useEffect } from "react"
import ProfileCard from "./profile-card"
import "./about.css"

import sliderArrow from "../../assets/carousel-arrow.svg"

const getRandInt = arr => {
  return Math.floor(Math.random() * arr.length)
}

const getCentered = id => {
  const el = document.getElementById(`child-${id}`)
  const prnt = document.getElementById(`parent`)

  el.scrollIntoView({
    block: "center",
    behavior: "smooth",
  })

  prnt.scrollLeft = el.offsetLeft - prnt.offsetLeft
}

const AboutUsSection = ({ title, researchers, cta }) => {
  const [slideIndex, setSlideIndex] = useState(getRandInt(researchers) || 0)

  const nextSlide = () => {
    if (slideIndex !== researchers.length - 1) {
      return setSlideIndex(slideIndex + 1)
    }
    setSlideIndex(0)
  }

  const prevSlide = () => {
    let max = researchers.length - 1
    if (slideIndex === 0) {
      return setSlideIndex(max)
    }
    setSlideIndex(slideIndex - 1)
  }

  useEffect(() => {
    getCentered(slideIndex)
  }, [slideIndex])

  return (
    <section className="about" id="about">
      <h2 className="about-title">
        {title[0]} <span>{title[1]}</span>
      </h2>
      <div className="slider-wrapper">
        <button onClick={prevSlide} className="slide-btn">
          <img src={sliderArrow} alt="slider arrow" className="arrow" />
        </button>
        <div className="slider" id="parent">
          {researchers.map((res, id) => (
            <div className="slide" key={id} id={`child-${id}`}>
              <ProfileCard {...res} cta={cta} />
            </div>
          ))}
        </div>
        <button onClick={nextSlide} className="slide-btn">
          <img src={sliderArrow} alt="slider arrow" className="arrow reverse" />
        </button>
      </div>
      <div className="radio-btn-wrapper">
        {researchers.map((_, id) => (
          <label key={id} htmlFor={id}>
            <input
              type="radio"
              value={id}
              id={id}
              onClick={() => setSlideIndex(id)}
              checked={slideIndex === id ? "checked" : false}
              readOnly
            />
            <span
              className={
                slideIndex === id
                  ? "slide-radio-btn--active"
                  : "slide-radio-btn"
              }
            />
          </label>
        ))}
      </div>
    </section>
  )
}

export default AboutUsSection
