import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/PageTitle/page-title"
import About from "../components/About/about"
import Promo from "../components/Promotions/promo"
import {
  ProjectIdea,
  ProjectAims,
  ProjectApplication,
  ProjectFunding,
} from "../components/Index/index-sections"
import { useStateValue } from "../contexts/state-context-provider"
import {
  getImage,
  getLanguageActivities,
  getFirstTwoActivities,
} from "../utils/utils"
import { pageContentHome } from "../markdown-text/content"

const IndexPage = () => {
  const { language, data } = useStateValue()
  const { markdown, images } = data
  const promoCards = getFirstTwoActivities(
    getLanguageActivities(markdown, language)
  )
  const targetImages = promoCards.map(item => {
    return getImage(item, images)
  })

  return (
    <Layout>
      <SEO title="Home" />
      <div style={{ display: "none" }}>
        <PageTitle hTitle="LEAPSynSCI project" />
      </div>
      <ProjectIdea />
      <ProjectAims />
      <ProjectApplication id="mod" />
      <ProjectFunding />
      <About
        title={pageContentHome[language].about.h}
        researchers={pageContentHome[language].about.researchers}
        cta={pageContentHome[language].about.cta}
        id="mod2"
      />
      <Promo id="mod3" promoCards={promoCards} targetImages={targetImages} />
    </Layout>
  )
}

export default IndexPage
