import React from "react"
import { Link } from "gatsby"
import "./promo.css"

const PromoCard = ({ markdown, image, selector }) => (
  <div className="promo-card">
    <div className="label">{markdown.frontmatter.label}</div>
    <div className="row">
      <Link to={`/activities/#act-${selector}`}>
        <img className="image" src={image} alt={markdown.frontmatter.image} />
      </Link>
    </div>
    <div className="row ">
      <div className="date">{markdown.frontmatter.date}</div>
      <h5 className="title">{markdown.frontmatter.title}</h5>

      <p
        className="ph"
        dangerouslySetInnerHTML={{
          __html: markdown.html,
        }}
      />
    </div>
  </div>
)

const Promo = ({ promoCards, targetImages }) => {
  return (
    <section className="promo">
      <div className="flex-row">
        {promoCards.map((markdown, id) => (
          <PromoCard
            key={id}
            markdown={markdown}
            image={targetImages[id]}
            selector={id}
          />
        ))}
      </div>
      <Link className="cta-btn" to="/activities">
        Checkout all LEAPSyn-SCI Activities
      </Link>
    </section>
  )
}

export default Promo
