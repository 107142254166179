import React from "react"

import "./index-sections.css"
import bgMain from "../../assets/bg-main-1.svg"
import fundIconRs from "../../assets/scifund-rs.png"
import fundIconEn from "../../assets/scifund-en.png"
import { pageContentHome } from "../../markdown-text/content"
import { useStateValue } from "../../contexts/state-context-provider"

export const ProjectIdea = () => {
  const { language } = useStateValue()

  return (
    <section className="first-sub-sec">
      <img src={bgMain} alt="circle vectors" className="bg-vec" />
      <div className="fst">
        <h2 className="title">
          {pageContentHome[language].pi.h[0]}{" "}
          <span>{pageContentHome[language].pi.h[1]}</span>
        </h2>
        <p className="ph">{pageContentHome[language].pi.ph}</p>
      </div>
      <div className="snd">
        <div className="feed-wrapper">
          <a
            className="twitter-timeline"
            href="https://twitter.com/leapsyn"
            data-chrome="nofooter"
          >
            Tweets by @leapsyn
          </a>
        </div>
      </div>
    </section>
  )
}

export const ProjectAims = () => {
  const { language } = useStateValue()

  const FrameTempalte = ({ title, cnt, year, order }) => {
    return (
      <div className={`frame ${order ? "frame-start" : "frame-end"}`}>
        <div className="cnt-wrapper">
          <h4 className="title title-mod">{title}</h4>
          <p className="ph">{cnt}</p>
        </div>
        <span className="frame-year">{year}</span>
      </div>
    )
  }

  return (
    <section className="second-sub-sec">
      <h2 className="title">
        {pageContentHome[language].pa.h[0]}{" "}
        <span>{pageContentHome[language].pa.h[1]}</span>
      </h2>
      <div className="flexbox">
        <FrameTempalte order {...pageContentHome[language].pa.aims[0]} />
        <FrameTempalte {...pageContentHome[language].pa.aims[1]} />
      </div>
      <div className="flexbox">
        <FrameTempalte order {...pageContentHome[language].pa.aims[2]} />
        <FrameTempalte {...pageContentHome[language].pa.aims[3]} />
      </div>
    </section>
  )
}

export const ProjectApplication = () => {
  const { language } = useStateValue()
  return (
    <section className="thrd-sub-sec">
      <h2 className="title">{pageContentHome[language].pn.h}</h2>
      <h3 className="subtitle">{pageContentHome[language].pn.sh}</h3>
      <p className="ph">{pageContentHome[language].pn.ph}</p>
      <blockquote>{pageContentHome[language].pn.bq}</blockquote>
    </section>
  )
}

export const ProjectFunding = () => {
  const { language } = useStateValue()
  const fundIcon = {
    en: fundIconEn,
    rs: fundIconRs,
  }
  return (
    <section className="main-funding">
      <div className="logo-wrapper">
        <img src={fundIcon[language]} alt="" className="scifund-logo" />
      </div>
      <p className="ph">
        {pageContentHome[language].pf.ph}
        <br />
        <a
          target="_blank"
          rel="noreferrer"
          href="http://fondzanauku.gov.rs/poziv/2019/06/promis/"
          className="scifund-link"
        >
          {pageContentHome[language].pf.cta[0]}
        </a>{" "}
        {pageContentHome[language].pf.cta[1]}{" "}
        <strong>{pageContentHome[language].pf.cta[2]}</strong>.
      </p>
    </section>
  )
}
