import React, { useState } from "react"
import { Link } from "gatsby"
import "./profile-card.css"

import icon1 from "../../assets/profile-icons/icon-1.svg"
import icon2 from "../../assets/profile-icons/icon-2.svg"
import icon3 from "../../assets/profile-icons/icon-3.svg"
import icon4 from "../../assets/profile-icons/icon-4.svg"
import icon5 from "../../assets/profile-icons/icon-5.svg"

import marija from "../../assets/profile-images/marija.png"
import jelena from "../../assets/profile-images/jelena.png"
import sonja from "../../assets/profile-images/sonja.png"
import milena from "../../assets/profile-images/milena.png"
import natasa from "../../assets/profile-images/natasa.png"
import strahinja from "../../assets/profile-images/strahinja.png"
import sonjav from "../../assets/profile-images/sonjav.png"

const ProfileCard = ({
  name,
  image,
  subname,
  exp,
  fields,
  customIcon,
  orcidId,
  scopus,
  cta,
}) => {
  var iconSelect = {
    1: icon1,
    2: icon2,
    3: icon3,
    4: icon4,
    5: icon5,
  }
  var imageSelect = {
    marija: marija,
    jelena: jelena,
    sonja: sonja,
    milena: milena,
    natasa: natasa,
    strahinja: strahinja,
    sonjav: sonjav,
  }
  let temp = subname.split(",").map(sp => sp)
  const [touch, setTouch] = useState(false)
  return (
    <div className="profile-card">
      <div className="main">
        <div className="img-frame">
          <img
            className="img"
            src={imageSelect[image]}
            alt={`profile - ${image}`}
          />
        </div>
        <div className="title">{name}</div>
        <div className="subtitle">
          {temp[0]}
          <br />
          {temp[1]}
        </div>
        <button
          className="cta-button--desktop"
          onClick={() => setTouch(!touch)}
        >
          {cta[0]}
        </button>
      </div>
      <div className="side">
        <ul className="side-items">
          <li className="first-item">{exp}</li>
          <li
            className="scnd-item"
            style={{ backgroundImage: `url(${iconSelect[customIcon]})` }}
          >
            {fields[0]}
          </li>
          {fields.slice(1).map((field, index) => (
            <li className="rest-item" key={index}>
              {field}
            </li>
          ))}
        </ul>
      </div>
      <div
        className={touch ? "visit-card activate" : "visit-card"}
        onClick={() => setTouch(false)}
      >
        <ul className="visit-card--details">
          <li className="visit-card--id">{orcidId}</li>
          <li className="visit-card--scopus">{scopus}</li>
          <li>
            <Link className="visit-card--link" to={`/contact/#${image}-mod`}>
              {cta[1]}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ProfileCard
